<template>
  <div>
    <div class="company">
      <el-form :model="enterTalentsInfo" :rules="enterRules">
        <el-form-item label="名称：" label-width="120px" style="margin-bottom:0px" prop="solutionName">
          <el-input style="width:470px" size="small" v-model="enterTalentsInfo.solutionName"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="mainIndex">
      <el-card>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="first">
            <person-message
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              ref="personMessage"
            ></person-message>
          </el-tab-pane>
          <!-- <el-tab-pane label="架构" name="second">
          <contact-us
            :editTalentInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="contactInfo"
          ></contact-us>
          </el-tab-pane>-->
          <el-tab-pane label="功能模块" name="second">
            <work-history :personData="personData" :personType="personType" ref="workHistory"></work-history>
          </el-tab-pane>
          <el-tab-pane label="附件上传" name="third">
            <the-paper :personData="personData" :personType="personType" ref="thePaper"></the-paper>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <div class="bottom" v-if="!personType">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getSchemeOne, addScheme } from '@/api/scheme'
import PersonMessage from './info.vue'
import ContactUs from './instruct.vue'
import WorkHistory from './function.vue'
import ThePaper from './file'
import UploadImage from '../../../components/UploadImage.vue'
export default {
  name: 'editTalentXS',
  components: {
    PersonMessage,
    ContactUs,
    WorkHistory,
    ThePaper,
  },
  computed: {
    personData() {
      return Number(this.$route.query.personData)
    },
    personType() {
      return Number(this.$route.query.personType)
    },
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  created() {
    if (this.personType) {
      this.getTalents()
    }
  },
  data() {
    return {
      activeName: 'first',
      isShowInput: false,
      // 表单校验信息
      enterTalentsInfo: {
        // 编号
        id: '',
        // 所属企业
        comName: '',
        // 行业标签
        industryTag: [],
        // 领域标签
        fieldTag: [],
        // 技术类别
        solutionTechnology: [],
        // 方案名称
        solutionName: '',
        companyCode: '',
        // 产品图片
        productImg: [],
        productImgs: [],
        // 架构图片
        frameworkImg: [],
        frameworkImgs: [],
        // 应用场景图片
        sceneApplicationImg: [],
        sceneApplicationImgs: [],
        // 数据结构
        dataStructure: '',
        // 方案设计
        programmeDesign: '',
        // 方案优势
        caseAdvantage: '',
        // 应用场景
        solutionIntro: '',
        // 价值
        value: '',
        // 需求
        solutionDemand: '',
        fieldChildrenId: [],
        industryChildrenId: [],
        techChildrenId: [],
        temporarySceneApplicationImg: [],
        temporaryFrameworkImg: [],
        temporaryProductImg: [],
      },
      enterRules: {
        solutionName: [
          { required: true, message: '请填写方案名称', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    // 查询个人信息
    async getTalents() {
      const params = {
        id: this.personData,
      }
      const { data: res } = await getSchemeOne(params)
      this.enterTalentsInfo = res
      this.enterTalentsInfo.industryTag = this.formatString(
        this.enterTalentsInfo.industryTag
      )
      this.enterTalentsInfo.fieldTag = this.formatString(
        this.enterTalentsInfo.fieldTag
      )
      this.enterTalentsInfo.solutionTechnology = this.formatString(
        this.enterTalentsInfo.solutionTechnology
      )
      this.enterTalentsInfo.productImg = this.formatString(
        this.enterTalentsInfo.productImg
      )
      this.enterTalentsInfo.productImgs = this.formatString(
        this.enterTalentsInfo.productImgs
      )
      this.enterTalentsInfo.frameworkImg = this.formatString(
        this.enterTalentsInfo.frameworkImg
      )
      this.enterTalentsInfo.frameworkImgs = this.formatString(
        this.enterTalentsInfo.frameworkImgs
      )
      this.enterTalentsInfo.sceneApplicationImg = this.formatString(
        this.enterTalentsInfo.sceneApplicationImg
      )
      this.enterTalentsInfo.sceneApplicationImgs = this.formatString(
        this.enterTalentsInfo.sceneApplicationImgs
      )
      if (this.personType) {
        if (this.enterTalentsInfo.temporaryProductImg) {
          this.enterTalentsInfo.productImgs =
            this.enterTalentsInfo.temporaryProductImg
        }
        if (this.enterTalentsInfo.temporaryFrameworkImg) {
          this.enterTalentsInfo.frameworkImgs =
            this.enterTalentsInfo.temporaryFrameworkImg
        }
        if (this.enterTalentsInfo.temporarySceneApplicationImg) {
          this.enterTalentsInfo.sceneApplicationImgs =
            this.enterTalentsInfo.temporarySceneApplicationImg
        }
      }
      // if(this.enterTalentsInfo.solutionPlanTitle.length>0){
      //   this.enterTalentsInfo.solutionPlanTitle = this.formatString(this.enterTalentsInfo.solutionPlanTitle)
      // }
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    // 全部保存
    saveItem() {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization,
      })
      let res
      this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
        //async
        if (valid) {
          if (this.enterTalentsInfo.fieldChildrenId) {
            this.enterTalentsInfo.fieldTag =
              this.enterTalentsInfo.fieldTag.concat(
                this.enterTalentsInfo.fieldChildrenId
              )
          }
          if (this.enterTalentsInfo.techChildrenId) {
            this.enterTalentsInfo.solutionTechnology =
              this.enterTalentsInfo.solutionTechnology.concat(
                this.enterTalentsInfo.techChildrenId
              )
          }
          if (this.enterTalentsInfo.industryChildrenId) {
            this.enterTalentsInfo.industryTag =
              this.enterTalentsInfo.industryTag.concat(
                this.enterTalentsInfo.industryChildrenId
              )
          }
          const params = {
            solutionDto: this.enterTalentsInfo,
            solutionModules: this.$refs.workHistory.data,
            solutionFiles: this.$refs.thePaper.form2,
          }
          // 新增
          if (this.enterTalentsInfo.solutionName == '') {
            this.$message.error('请填写人才名称')
          } else {
            res = await addScheme(params)
          }
          if (res.code === 200) {
            this.$message.success(`${this.personType ? '编辑' : '新增'}成功`)
            this.$router.go(-1)
          }
        }
      })
    },
    // 返回
    cancelItem() {
      this.$router.go(-1)
    },
    editClick() {
      this.isShowInput = true
    },
  },
  mounted() {
    if (this.personType) {
      // document.querySelector('.el-tabs__content').style.height =
      // document.body.clientHeight - 310 + 'px'
    } else {
      document.querySelector('.el-tabs__content').style.height =
        document.body.clientHeight - 350 + 'px'
    }
  },
}
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
}
/deep/ .el-tabs__content {
  overflow: auto;
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}
// .mainIndex{
//   height: 650px;
//   overflow: auto;
// }
</style>
