<template>
  <div>
    <div class="infoOne">
      <el-form
        label-width="120px"
        :model="editTalentInfo"
        :rules="enterRules"
        ref="editTalentInfo"
        v-if="isShow"
      >
        <el-form-item label="企业名称：" prop="companyCode">
          <el-select
            v-model="editTalentInfo.companyCode"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            :clearable="true"
          >
            <el-option
              v-for="(item,index) in options"
              :key="index"
              :label="item.comName"
              :value="item.companyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领域类别：" prop="fieldTag">
          <dic-checkbox-group
            :code.sync="editTalentInfo.fieldTag"
            tag="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="行业类别：" prop="industryTag">
          <dic-checkbox-group
            :code.sync="editTalentInfo.industryTag"
            tag="行业"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="技术类别：" prop="solutionTechnology">
          <dic-checkbox-group
            :code.sync="editTalentInfo.solutionTechnology"
            tag="技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域标签：" v-if="personType">
          <el-input v-model="editTalentInfo.wisdomTag1" style="width:80%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
        </el-form-item>
        <el-form-item label="行业标签：" v-if="personType">
          <el-input v-model="editTalentInfo.IndustryTag1" style="width:80%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
        </el-form-item>
        <el-form-item label="技术标签：" v-if="personType">
          <el-input v-model="editTalentInfo.aiTag1" style="width:80%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
        </el-form-item>
        <el-form-item label="需求：">
          <el-input
            type="textarea"
            style="width:80%"
            :autosize="{minRows:4,maxRows:20}"
            v-model="editTalentInfo.solutionDemand"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="方案设计：">
        <el-input size="small"></el-input>
        </el-form-item>-->
        <el-form-item label="价值：">
          <el-input
            type="textarea"
            style="width:80%"
            :autosize="{minRows:4,maxRows:20}"
            v-model="editTalentInfo.value"
          ></el-input>
        </el-form-item>
        <el-form-item label="应用场景：">
          <el-input
            type="textarea"
            style="width:80%"
            :autosize="{minRows:4,maxRows:20}"
            v-model="editTalentInfo.solutionIntro"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="产品：">
        <el-input size="small"></el-input>
        </el-form-item>-->
        <el-form-item label="方案优势：">
          <el-input
            type="textarea"
            style="width:80%"
            :autosize="{minRows:4,maxRows:20}"
            v-model="editTalentInfo.caseAdvantage"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品图片：" prop="productImg">
          <div class="picture">
            <p style="margin-bottom:15px;padding-left:500px;color:#606266;font-size:14px">
              <span style="color:red">*</span>每个模块图片最多添加五张
            </p>
            <UpLoadImage
              @upLoadImgList="clickTu"
              @upOldLoadImgList="clickOldTu"
              :upLoadImgList.sync="editTalentInfo.productImgs"
              :upOldLoadImgList.sync="editTalentInfo.productImg"
            />
          </div>
        </el-form-item>
        <el-form-item label="架构图片：">
          <UpLoadImage
            @upLoadImgList="clickTu1"
            @upOldLoadImgList="clickOldTu1"
            :upLoadImgList.sync="editTalentInfo.frameworkImgs"
            :upOldLoadImgList.sync="editTalentInfo.frameworkImg"
          />
        </el-form-item>
        <el-form-item label="应用场景图片：">
          <UpLoadImage
            @upLoadImgList="clickTu2"
            @upOldLoadImgList="clickOldTu2"
            :upLoadImgList.sync="editTalentInfo.sceneApplicationImgs"
            :upOldLoadImgList.sync="editTalentInfo.sceneApplicationImg"
          />
        </el-form-item>
        <el-form-item label="成功案例：" v-if="personType">
          <el-input v-model="editTalentInfo.solutionPlanTitle" type="textarea" style="width:80%"></el-input>
        </el-form-item>
                <el-form-item label="关键词：" v-if="personType">
          <el-input
            v-model="editTalentInfo.antistop"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item>
      </el-form>
                <div class="ess_people" v-if="personType">
        <div class="ess_people_list">
          创建人：{{ editTalentInfo.adminName }}
        </div>
        <div class="ess_people_list">
          修改人：{{ editTalentInfo.updateName }}
        </div>
        <div class="ess_people_list">
          创建时间：{{ editTalentInfo.creationTime}}
        </div>
        <div class="ess_people_list">
          修改时间：{{ editTalentInfo.updateTime}}
        </div>
      </div>
    </div>
    <div class="bottom" v-if="personType">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import UpLoadImage from '../UpLoadImage/index.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import { companySearch, successSearch, editScheme } from '@/api/scheme'
export default {
  components: {
    UpLoadImage,
    DicCheckboxGroup,
  },
  created() {
    setTimeout(() => {
      this.isShow = true
      if (this.personType) {
        this.options = [
          {
            comName: this.editTalentInfo.comName,
            companyCode: this.editTalentInfo.companyCode,
          },
        ]
      }
    }, 1000)
    // this.remoteMethod1()
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: Number,
    },
  },
  data() {
    return {
      options: [],
      options1: [],
      loading: false,
      loading1: false,
      isShow: false,
      enterRules: {
        companyCode: [
          { required: true, message: '请输入所属企业', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    updateCode(val, tag) {
      switch (tag) {
        case '领域':
          this.editTalentInfo.fieldChildrenId = val
          break

        case '行业':
          this.editTalentInfo.industryChildrenId = val
          break

        case '技术':
          this.editTalentInfo.techChildrenId = val
          break

        default:
          break
      }
    },
    //上传组件图片地址
    clickOldTu(val) {
      this.editTalentInfo.productImg = val
    },
    clickTu(val) {
      this.editTalentInfo.productImgs = val
    },
    clickOldTu1(val) {
      this.editTalentInfo.frameworkImg = val
    },
    clickTu1(val) {
      this.editTalentInfo.frameworkImgs = val
    },
    clickOldTu2(val) {
      this.editTalentInfo.sceneApplicationImg = val
    },
    clickTu2(val) {
      this.editTalentInfo.sceneApplicationImgs = val
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(async () => {
          this.loading = false
          const res = await companySearch({ comName: query })
          this.options = res.data
        }, 200)
      } else {
        this.options = []
      }
    },
    // async remoteMethod1() {
    //   const res = await successSearch()
    // },
    saveItem() {
      // 编辑
      this.$refs.editTalentInfo.validate(async (valid) => {
        //async
        if (valid) {
          if (this.editTalentInfo.fieldChildrenId) {
            this.editTalentInfo.fieldTag = this.editTalentInfo.fieldTag.concat(
              this.editTalentInfo.fieldChildrenId
            )
          }
          if (this.editTalentInfo.techChildrenId) {
            this.editTalentInfo.solutionTechnology =
              this.editTalentInfo.solutionTechnology.concat(
                this.editTalentInfo.techChildrenId
              )
          }
          if (this.editTalentInfo.industryChildrenId) {
            this.editTalentInfo.industryTag =
              this.editTalentInfo.industryTag.concat(
                this.editTalentInfo.industryChildrenId
              )
          }
          if (this.editTalentInfo.solutionName == '') {
            this.$message.error('请填写方案名称')
          } else {
            if (this.editTalentInfo.productImgs) {
              this.editTalentInfo.productImgs = []
            }
            if (this.editTalentInfo.frameworkImgs) {
              this.editTalentInfo.frameworkImgs = []
            }
            if (this.editTalentInfo.sceneApplicationImgs) {
              this.editTalentInfo.sceneApplicationImgs = []
            }
            const res = await editScheme(this.editTalentInfo)
            if (res.code == 200) {
              this.$router.go(-1)
              this.$message.success('基本信息修改成功')
            }
          }
          if (res.code === 200) {
            this.$message.success(`${this.personType ? '编辑' : '新增'}成功`)
          }
        }
      })
    },
    cancelItem() {
      this.$router.go(-1)
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName('infoOne')[0].style.height =
        document.body.clientHeight - 330 + 'px'
    }
  },
}
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 470px !important;
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.infoOne {
  // height: 600px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.picture {
  position: relative;
  p {
    position: absolute;
    right: 20%;
  }
}
.ess_people {
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    margin: 20px 30px;
    align-content: center;

    .ess_people_list {
      width: 100%;
      margin: 4px 0;
      color: #7c7f8e;
      font-size: 12px;
    }
  }
</style>
