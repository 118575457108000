<template>
  <div class="mainIndex">
    <el-form label-width="120px">
      <el-form-item label="架构终端层：">
        <el-input type="textarea" :autosize="{minRows:4,maxRows:8}"></el-input>
      </el-form-item>
      <el-form-item label="架构应用层：">
        <el-input type="textarea" :autosize="{minRows:4,maxRows:8}"></el-input>
      </el-form-item>
      <el-form-item label="架构服务层：">
        <el-input type="textarea" :autosize="{minRows:4,maxRows:8}"></el-input>
      </el-form-item>
      <el-form-item label="IASS：">
        <el-input type="textarea" :autosize="{minRows:4,maxRows:8}"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editTalentInfo: {},
    }
  },
  methods: {
    updateCode() {},
  },
}
</script>

<style lang="less" scoped>
/deep/.el-textarea {
  width: 470px !important;
  // min-height: 40px !important;
}
.mainIndex {
  height: 700px;
  overflow: auto;
}
</style>
