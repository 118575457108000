<template>
  <div>
    <el-upload
      action="'"
      :file-list="explainImgList"
      accept="image/*"
      :limit="5"
      class="upload-demo"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :on-exceed="handleExceed"
      :on-error="omgUploadError"
      list-type="picture-card"
      :http-request="upload"
    >
      <!-- <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
      </span>-->
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- <div slot="tip" class="el-upload__tip">最多上传5张</div> -->
    <p :model="file" style="color:#606266" v-if="file.length>0">{{file}}</p>
  </div>
</template>
<script>
import { uploadFile } from '@/api/common'
export default {
  props: {
    upLoadImgList: {
      type: Array,
    },
    upOldLoadImgList: {
      type: Array,
    },
  },
  data() {
    return {
      explainImgList: [],
      file: [],
    }
  },
  created() {
    if (this.upLoadImgList.length < 1) {
      this.explainImgList = []
    } else {
      this.explainImgList = this.upLoadImgList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        }
      })
      this.file = this.explainImgList.map((item) => {
        return item.name.replace('elite-all/', ' ')
        // return item.name.replace('test/', ' ')
      })
      this.file = this.file.join(',')
    }
  },
  methods: {
    // 移除图片
    handleRemove(file, fileList) {
      this.explainImgList = fileList
      const tt = []
      const yy = []
      fileList.forEach((el) => {
        tt.push(el.name)
        yy.push(el.url)
      })
      this.$emit('upOldLoadImgList', tt)
      this.$emit('upLoadImgList', yy)

      this.file = this.explainImgList.map((item) => {
        return item.name.replace('elite-all/', ' ')
        // return item.name.replace('test/', ' ')
      })
      this.file = this.file.join(',')
    },

    // 文件上传之前调用做一些拦截限制
    beforeAvatarUpload(file, fileList) {
      const isJPG = true
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$messageError('图片太大')
      }
      return isJPG && isLt2M
    },

    // 文件上传成功
    handleAvatarSuccess(res, file) {
      this.file = this.explainImgList.map((item) => {
        return item.name.replace('elite-all/', ' ')
        // return item.name.replace('test/', ' ')
      })
      this.file = this.file.join(',')
    },

    handleExceed(files, fileList) {
      this.$message('只能上传五张图片')
    },

    omgUploadError(err, file, fileList) {
      this.$message('上传图片失败')
    },

    async upload(file) {
      const fileArray = []
      const form = new FormData()
      fileArray.push(file.file)
      fileArray.forEach((item, index) => {
        form.append('files', fileArray[index])
      })
      const res = await uploadFile(form)
      if (res.code == 200) {
        this.$emit(
          'upOldLoadImgList',
          this.upOldLoadImgList.concat(res.data.fileAddr)
        )
        this.$emit(
          'upLoadImgList',
          this.upLoadImgList.concat(res.data.fileLook)
        )
        this.explainImgList.push({
          name: file.file.name,
          uid: file.file.uid,
          url: res.data.fileLook[0],
        })
      }
    },
  },
}
</script>
