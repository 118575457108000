<template>
  <div>
    <div class="findone">
      <div v-for="(item, index) in data" :key="index">
        <el-form label-width="90px" :model="item">
          <el-form-item label="大类别：">
            <el-input v-model="item.typeName"></el-input>
            <i @click="add(index)" class="el-icon-circle-plus-outline"></i>
            <i
              @click="delet(index)"
              class="el-icon-remove-outline"
              v-if="index > 0"
            ></i>
          </el-form-item>
          <el-form-item label="主要功能：">
            <el-input
              v-model="item.function"
              type="textarea"
              :autosize="{ minRows: 2 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="支撑：">
            <el-input
              v-model="item.support"
              type="textarea"
              :autosize="{ minRows: 2 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能特点：">
            <el-input
              v-model="item.functionFeatures"
              type="textarea"
              :autosize="{ minRows: 2 }"
            ></el-input>
          </el-form-item>
          <div
            v-for="(el, indexSmall) in item.childNode"
            :key="indexSmall"
            style="padding-left: 100px"
          >
            <el-form-item label="小类别：">
              <el-input v-model="el.typeName"></el-input>
              <i
                @click="addmiddle(index)"
                class="el-icon-circle-plus-outline"
              ></i>
              <i
                @click="deletMiddle(index, indexSmall)"
                class="el-icon-remove-outline"
                v-if="indexSmall > 0"
              ></i>
            </el-form-item>
            <el-form-item label="主要功能：">
              <el-input
                v-model="el.function"
                type="textarea"
                :autosize="{ minRows: 2 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="支撑：">
              <el-input
                v-model="el.support"
                type="textarea"
                :autosize="{ minRows: 2 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="功能特点：">
              <el-input
                v-model="el.functionFeatures"
                type="textarea"
                :autosize="{ minRows: 2 }"
              ></el-input>
            </el-form-item>
            <div
              v-for="(item1, index1) in el.childNode"
              :key="index1"
              style="padding-left: 100px"
            >
              <el-form-item label="子类别：">
                <el-input v-model="item1.typeName"></el-input>
                <i
                  @click="addsmall(index, indexSmall)"
                  class="el-icon-circle-plus-outline"
                ></i>
                <i
                  @click="deletSmall(index, indexSmall, index1)"
                  class="el-icon-remove-outline"
                  v-if="index1 > 0"
                ></i>
              </el-form-item>
              <el-form-item label="主要功能：">
                <el-input
                  v-model="item1.function"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                ></el-input>
              </el-form-item>
              <el-form-item label="支撑：">
                <el-input
                  v-model="item1.support"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                ></el-input>
              </el-form-item>
              <el-form-item label="功能特点：">
                <el-input
                  v-model="item1.functionFeatures"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bottom" v-if="personType">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import { solutionModule, editSolutionModule } from "@/api/scheme";
export default {
  props: {
    personData: {
      type: Number
    },
    personType: {
      type: Number
    }
  },
  data() {
    return {
      data: [
        // 大类别
        {
          typeName: "",
          function: "",
          functionFeatures: "",
          support: "",
          childNode: [
            // 小类别
            {
              typeName: "",
              function: "",
              functionFeatures: "",
              support: "",
              childNode: [
                // 子类别
                {
                  typeName: "",
                  function: "",
                  functionFeatures: "",
                  support: ""
                }
              ]
            }
          ]
        }
      ]
    };
  },
  created() {
    if (this.personType) {
      this.solutionModules();
    }
  },
  methods: {
    updateCode() {},
    add() {
      const value = {
        typeName: "",
        function: "",
        functionFeatures: "",
        support: "",
        childNode: [
          // 小类别
          {
            typeName: "",
            function: "",
            functionFeatures: "",
            support: "",
            childNode: [
              // 子类别
              {
                typeName: "",
                function: "",
                functionFeatures: "",
                support: ""
              }
            ]
          }
        ]
      };
      this.data.push(value);
    },
    addmiddle(indexBig) {
      const value = {
        typeName: "",
        function: "",
        functionFeatures: "",
        support: "",
        childNode: [
          // 子类别
          {
            typeName: "",
            function: "",
            functionFeatures: "",
            support: ""
          }
        ]
      };
      this.data[indexBig].childNode.push(value);
    },
    addsmall(indexBig, indexMiddle) {
      const value = {
        typeName: "",
        function: "",
        functionFeatures: "",
        support: ""
      };
      this.data[indexBig].childNode[indexMiddle].childNode.push(value);
    },
    async saveItem() {
      // 编辑
      const res = await editSolutionModule(this.data);
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
    async solutionModules() {
      const params = {
        solutionId: this.personData
      };
      const res = await solutionModule(params);
      if (res.data.length < 1) {
      } else {
        this.data = res.data;
      }
    },
    delet(index) {
      this.data.splice(index, 1);
    },
    deletMiddle(index, indexSmall) {
      this.data[index].childNode.splice(indexSmall, 1);
    },
    deletSmall(index, indexSmall, index1) {
      this.data[index].childNode[indexSmall].childNode.splice(index1, 1);
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("findone")[0].style.height =
        document.body.clientHeight - 330 + "px";
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 470px !important;
}
/deep/.el-textarea {
  width: 700px !important;
  // min-height: 40px !important;
}
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.findone {
  // height: 600px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
